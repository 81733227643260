import React   from 'react';
// import './digital.scss';
import whiteLabel from "../../assets/white-label.png";
import b2c from "../../assets/b2c.png";
import operationSupport from "../../assets/operationSupport.png";
import riskManagement from "../../assets/risk-management.png";
import branding from "../../assets/branding.png";
import digitalMarketing from "../../assets/digital-marketing.png";
import Frame from "../../assets/Frame.png";
import graphicsDesign from "../../assets/graphics-design.png";

import Services from "../services/services"
function WhiteLabelComponent(){
  
  return (
    <>
      <div className=" servicePages">
          <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <h2>White Label</h2>
                    <p>Give your iGaming business the boost it needs by utilizing Game Mano’s professional services! From White Label and Turnkey solutions, to B2B Support, Digital Marketing, and everything in between, we've got you covered!</p>
                    <button>Connect Now</button>

                </div>
                <div className="col-lg-6 col-12">
                  <img src={whiteLabel} className="d-block mx-auto" />
                </div>
            </div>
          </div>
      </div>


      <Services />
      

    
        






    </>
  );
};



export default WhiteLabelComponent;
import React , { useEffect , useState , useRef }  from 'react';
import './homepage.scss';
import Marquee from "react-fast-marquee";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { Waypoint } from 'react-waypoint';

// import whiteLabel from "../../assets/white-label.png";
// import b2c from "../../assets/b2c.png";
// import operationSupport from "../../assets/operationSupport.png";
// import riskManagement from "../../assets/risk-management.png";
// import branding from "../../assets/branding.png";
// import digitalMarketing from "../../assets/digital-marketing.png";
// import Frame from "../../assets/Frame.png";
// import graphicsDesign from "../../assets/graphics-design.png";
// import VisibilitySensor from 'react-visibility-sensor';
import bulb from "../../assets/bulb.png";
import Services from "../services/services"


import oneicon from "../../assets/1.jpg";
import twoicon from "../../assets/2.jpg";
import threeicon from "../../assets/3.jpg";
import fouricon from "../../assets/4.jpg";
import fiveicon from "../../assets/5.jpg";
import sixicon from "../../assets/6.jpg";
import sevenicon from "../../assets/7.jpg";
import eighticon from "../../assets/8.jpg";

import two from "../../assets/2.png";
import three from "../../assets/3.png";
import five from "../../assets/5.png";
import six from "../../assets/6.png";
import seven from "../../assets/7.png";
import eight from "../../assets/8.png";


import slotmachine from "../../assets/jackpot.gif";
import slotmachine2 from "../../assets/jackpot2.gif";
import slotmachine3 from "../../assets/jackpot3.gif";
import slotmachine4 from "../../assets/jackpot4.gif";
import slotmachine5 from "../../assets/jackpot5.gif";
import slotmachine6 from "../../assets/jackpot6.gif";
import slotmachine7 from "../../assets/jackpot7.gif";
import slotmachine8 from "../../assets/jackpot8.gif";
import slotmachine9 from "../../assets/jackpot9.gif";
import slotmachine10 from "../../assets/jackpot10.gif";
import slotmachine11 from "../../assets/jackpot11.gif";
import slotmachine12 from "../../assets/jackpot12.gif";
 

import * as Scroll from 'react-scroll';

function HomepageComponent( props ){
    var Element  = Scroll.Element;
        var scroller = Scroll.scroller;
    const [disableScroll, setDisableScroll] = useState(false)
    const [convertClass , setConvertClass] = useState(false)
    const [opacity , setOpacity] = useState(false)
    const scollToRef = useRef();

    const BulbEnter = () => {
        setConvertClass(false)
        setOpacity(false)
    }
    const waypointEnter = () => {
      if(disableScroll === false){
          scollToRef.current.scrollIntoView()
          // scroller.scrollTo('serviceContainer', { duration: 250 });
          setConvertClass(true)
          setTimeout(() => {
            setOpacity(true)
          }, 1000);    
      }
    }
 
 
    useEffect(() => {
      window.onscroll = function(e) {
          setDisableScroll(this.oldScroll > this.scrollY);
          this.oldScroll = this.scrollY;
      }

      
    }, []);

    // Opera 8.0+

     
    const particlesInit = async (main) => { await loadFull(main);};
    // const particlesLoaded = (container) => { /* console.log(container); */ };
    return (
      <>
      <div  className={opacity ? "hideholaaaa" : "showholaaaa"}>
     <Particles
        id="tsparticles"
        init={particlesInit}
        // loaded={particlesLoaded}
        options={{
          
          // fpsLimit: 20,
          interactivity: {
            // events: {
            //   onClick: {
            //     enable: true,
            //     mode: "push",
            //   },
            //   onHover: {
            //     enable: true,
            //     mode: "repulse",
            //   },
            //   // resize: true,
            // },
            // modes: {
             
            //   repulse: {
            //     distance: 200,
            //     duration: 0.4,
            //   },
            // },
          },
          particles: {
            color: {
              value: "#ffffff",
            },
            links: {
              color: "#ffffff",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,
              speed: 1,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 1, max: 5 },
            },
          },
          // detectRetina: true,
        }}
      /> 
</div>
        <div className="bannerSection">
       
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-12">
                    <h2>A Complete Solution to<br></br>Your <span>Online Game Development</span></h2>
                    <p style={{marginTop:"15px", marginBottom: "35px"}}>Game Mano is a product development company that exclusively focuses on game entertainment. We craft beautiful and exciting games that people want to continue playing. With our efficient industry experience, we will take care of your gaming needs from start to finish.</p>
                    <a href="contact"><button>Connect Now</button></a>
                    </div>
                    <div className="col-lg-6 col-12">
   
                    </div>
                </div>

            </div>
     
        </div>
          <div className="bulbAnimator serviceContainer">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-12">
                  <Waypoint onEnter={BulbEnter} />
                  <Element name="bulbContainer">
                    <div className='bulbContainerLol' style={{padding: "80px 0px"}}>  
                      <img src={bulb} style={{ width: "388px"}} className="d-block mx-auto bulbImage" />
                    </div>
                  </Element>
                </div>
          
                <div ref={scollToRef} className='serviceContainer'>
                  <Element name="serviceContainer">
                  <Waypoint onEnter={waypointEnter} />

                    <div className="container" style={{    padding: "40px 0px"}}>
                      <div className="row rowBox" >
                        <div style={{padding: "0px 20px"}} class="col-12 col-lg-12">
                          <h3 style={{ color: "#009", fontSize: "37px", fontWeight: "bold"}}> UNIQUE POINTS </h3>
                        </div>
                      </div>
                      {/* <Waypoint onEnter={waypointEnter} /> */}

                      <div className="row rowBox"  >
                        <div className="col-xl-3 col-lg-3 col-sm-4 col-6 pointBox"> 
                        
                          <div className={convertClass ? 'iconTransform' : "iconTransformOne"} id="uniquepointImg">
                            <img className={opacity ? "hideholaaaa" : "showholaaaa"} src={oneicon} />
                            {/* <Lottie loop animationData={cards} play style={{ display: "block", margin: "auto"}} /> */}
                          </div>
                          <div className={convertClass === false ? 'hideholaaaa' : "holaaaa"} id="uniquepointImgTwo">
                            {/* <Lottie className={opacity ? "showholaaaa" : "hideholaaaa"} loop animationData={cards} play style={{ display: "block", margin: "auto"}} /> */}
                            <img className={opacity ? "showholaaaa" : "hideholaaaa"} src={slotmachine} />

                          </div>
                          <div  className={opacity ? "showholaaaa" : "hideholaaaa"} >
                            <div className="row featureRow">
                              <div className="col-lg-6 featureLeft">
                                  <h3 class="text-right">6200+</h3>
                              </div>
                              <div className="col-lg-6 featureRight">
                                  <h3 class="">Casino Games+</h3>
                              </div>
                            </div>
                          </div>
                         
                        
                        </div>
                        <div className="col-xl-3 col-lg-3 col-sm-4 col-6 pointBox"> 
                          <div className={convertClass ? 'iconTransform' : "iconTransformTwo"} id="uniquepointImg">
                            <img  className={opacity ? "hideholaaaa" : "showholaaaa"} src={twoicon} id={ navigator.userAgent.indexOf("OP") > -1 ? 'operaaa' : "notopera"}/>
                            {/* <Lottie loop animationData={onlinecasino} play style={{ display: "block", margin: "auto"}} /> */}
                          </div>
                          <div className={convertClass === false ? 'hideholaaaa' : "holaaaa"} id="uniquepointImgTwo">
                            {/* <Lottie className={opacity ? "showholaaaa" : "hideholaaaa"} loop animationData={onlinecasino} play style={{ display: "block", margin: "auto"}} /> */}
                            <img className={opacity ? "showholaaaa" : "hideholaaaa"} src={slotmachine2} />

                          </div>
                          <div  className={opacity ? "showholaaaa" : "hideholaaaa"} >
                            <div className="row featureRow">
                              <div className="col-lg-4 featureLeft">
                                  <h3  class="text-right">100</h3>
                              </div>
                              <div className="col-lg-8 featureRight">
                                  <h3 class="">Online Casino <br></br>Games Providers</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-sm-4 col-6 pointBox"> 
                          <div className={convertClass ? 'iconTransform' : "iconTransformThree"} id="uniquepointImg">
                            <img  className={opacity ? "hideholaaaa" : "showholaaaa"} src={threeicon} id={ navigator.userAgent.indexOf("OP") > -1 ? 'operaaa' : "notopera"}/>
                            {/* <img src={slotmachine} /> */}
                          </div>
                          <div className={convertClass === false ? 'hideholaaaa' : "holaaaa"} id="uniquepointImgTwo">
                            <img className={opacity ? "showholaaaa" : "hideholaaaa"} src={slotmachine3} />                          
                          </div>  
                          <div  className={opacity ? "showholaaaa" : "hideholaaaa"} >
                            <div className="row featureRow">
                              <div className="col-lg-6 featureLeft">
                                  <h3  class="text-right">80+</h3>
                              </div>
                              <div className="col-lg-6 featureRight">
                                  <h3 class="">JackPot Games</h3>
                              </div>
                            </div>
                          </div>
                            
                        
                        </div>
                       
                        <div className="col-xl-3 col-lg-3 col-sm-4 col-6 pointBox"> 
                          <div  className={convertClass ? 'iconTransform' : "iconTransformFour"} id="uniquepointImg">
                            <img src={eighticon} className={opacity ? "hideholaaaa" : "showholaaaa"} id={ navigator.userAgent.indexOf("OP") > -1 ? 'operaaa' : "notopera"} />
                            {/* <Lottie loop animationData={twentyfour} play style={{ display: "block", margin: "auto"}} /> */}
                          </div>
                          <div className={convertClass === false ? 'hideholaaaa' : "holaaaa"} id="uniquepointImgTwo">
                            {/* <Lottie loop animationData={twentyfour} play style={{ display: "block", margin: "auto"}} /> */}
                            <img className={opacity ? "showholaaaa" : "hideholaaaa"} src={slotmachine8} />

                          </div>
                          <div  className={opacity ? "showholaaaa" : "hideholaaaa"} >
                            <div className="row featureRow">
                            <div className="col-lg-6 featureLeft">
                                <h3  class="text-right">24/7</h3>
                            </div>
                            <div className="col-lg-6 featureRight">
                                <h3 class="">Operator Access</h3>
                            </div>
                          </div> 
                          </div>
                          
                        </div>
                        {/* <Waypoint onEnter={waypointEnter} /> */}
                        <div className="col-xl-3 col-lg-3 col-sm-4 col-6 pointBox"> 
                        <div  className={convertClass ? 'iconTransform' : "iconTransformFive"} id="uniquepointImg">
                            <img src={slotmachine9}  className={opacity ? "hideholaaaa" : "showholaaaa"} id={ navigator.userAgent.indexOf("OP") > -1 ? 'operaaa' : "notopera"} />
                            
                            {/* <Lottie loop animationData={cloud} play style={{ display: "block", margin: "auto"}} /> */}
                          </div>
                          <div className={convertClass === false ? 'hideholaaaa' : "holaaaa"} id="uniquepointImgTwo">
                            {/* <Lottie loop animationData={twentyfour} play style={{ display: "block", margin: "auto"}} /> */}
                            <img className={opacity ? "showholaaaa" : "hideholaaaa"} src={slotmachine9} />

                          </div>
                          <div  className={opacity ? "showholaaaa" : "hideholaaaa"} >

                            <div className="row featureRow">
                              <div className="col-lg-12 featureRight">
                                  <h3 class="">Fully Cloud Based Setup</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" col-xl-3 col-lg-3 col-sm-4 col-6 pointBox"> 
                        <div  className={convertClass ? 'iconTransform' : "iconTransformSix"} id="uniquepointImg">
                          <img src={slotmachine10}   className={opacity ? "hideholaaaa" : "showholaaaa"} id={ navigator.userAgent.indexOf("OP") > -1 ? 'operaaa' : "notopera"} />
                              {/* <Lottie loop animationData={microservice} play style={{ display: "block", margin: "auto"}} /> */}
                          </div>
                          <div className={convertClass === false ? 'hideholaaaa' : "holaaaa"} id="uniquepointImgTwo">
                            {/* <Lottie loop animationData={twentyfour} play style={{ display: "block", margin: "auto"}} /> */}
                            <img className={opacity ? "showholaaaa" : "hideholaaaa"} src={slotmachine10} />

                          </div>
                          <div  className={opacity ? "showholaaaa" : "hideholaaaa"} >

                            <div className="row featureRow">
                              <div className="col-lg-12 featureRight">
                                  <h3 class="">Microservice Architecture</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-sm-4 col-6 pointBox"> 
                          <div className={convertClass ? 'iconTransform' : "iconTransformSeven"} id="uniquepointImg" >
                              <img src={slotmachine11}   className={opacity ? "hideholaaaa" : "showholaaaa"} id={ navigator.userAgent.indexOf("OP") > -1 ? 'operaaa' : "notopera"} />
                              {/* <Lottie loop animationData={automation} play style={{ display: "block", margin: "auto"}} /> */}
                          </div>
                          <div className={convertClass === false ? 'hideholaaaa' : "holaaaa"} id="uniquepointImgTwo">
                            {/* <Lottie loop animationData={twentyfour} play style={{ display: "block", margin: "auto"}} /> */}
                            <img className={opacity ? "showholaaaa" : "hideholaaaa"} src={slotmachine11} />

                          </div>
                          <div  className={opacity ? "showholaaaa" : "hideholaaaa"} >

                          <div className="row featureRow">
                            <div className="col-lg-12 featureRight">
                                <h3 class="">Fully Redundant and Auto Scaling Setup</h3>
                            </div>
                          </div>
                          </div>
                        </div> 
                        <div className="col-xl-3 col-lg-3 col-sm-4 col-6 pointBox"> 
                          <div className={convertClass ? 'iconTransform' : "iconTransformEight"} id="uniquepointImg">
                              <img src={slotmachine12}  className={opacity ? "hideholaaaa" : "showholaaaa"} id={ navigator.userAgent.indexOf("OP") > -1 ? 'operaaa' : "notopera"} />
                              {/* <Lottie loop animationData={cloudhosting} play style={{ display: "block", margin: "auto"}} /> */}
                          </div>
                          <div className={convertClass === false ? 'hideholaaaa' : "holaaaa"} id="uniquepointImgTwo">
                            {/* <Lottie loop animationData={twentyfour} play style={{ display: "block", margin: "auto"}} /> */}
                            <img className={opacity ? "showholaaaa" : "hideholaaaa"} src={slotmachine12} />

                          </div>
                          <div  className={opacity ? "showholaaaa" : "hideholaaaa"} >

                          <div className="row featureRow">
                            <div className="col-lg-12 featureRight">
                                <h3 class="">Database Always-on Setup</h3>
                            </div>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Element>
                </div>
              </div>
            </div>
          </div>
           {/* <VisibilitySensor partialVisibility={true} onChange={isBulbVisible}>  */}
           
          <Services />



        <div className="sectionTwo"   >

            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className='text-center'>Our Vendors</h2>
                        <p className='text-center'>Join our mission in driving success for the most ambitious companies in the gaming industry and beyond! Let’s raise the bar in taking our clients to the next level, while providing users with unique, fun, and secure experiences that they will not forget! Join us for this exciting ride!</p>
                    </div>
                </div>
            </div>
            {/* <div className="col-lg-12">
                      <Marquee speed={200}>
                        <img src={two} />
                        <img src={five} /> 
                        <img src={six} /> 
                        <img src={seven} /> 
                        <img src={three} /> 
                        <img src={eight} /> 
                      </Marquee>
                      <Marquee speed={200} direction="right" >
                        <img src={two} /> 
                        <img src={five} /> 
                        <img src={six} /> 
                        <img src={seven} /> 
                        <img src={three} /> 
                        <img src={eight} /> 
                      </Marquee>
            </div> */}
            
        </div>
      </>
    );

};



export default HomepageComponent;
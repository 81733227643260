import React from "react";


import './terms.css';


function TermsComponent(){
  
    
  return (
    <>
       <>  
      <div className="termsandcondition ">
        <div className="container">
          <div className="pageBox pageBoxx">
          <div className="row mt-4 mb-4">
                    <div className="col-12 faqTitle">
                        {/* <h4 style={{color: "#00C1DB", fontSize: "16px"}} className="text-center">The FAQ's</h4> */}
                        <h2 className='text-center'>Terms and Conditions</h2>
                    </div>
                </div>
          <h3>INDEMNIFICATION AGREEMENT</h3>
          <p>PLEASE READ THESE TERMS (THIS "AGREEMENT") CAREFULLY AS THEY CONSTITUTE A LEGAL AGREEMENT BETWEEN YOU ("CUSTOMER") AND [GAMEMANO]. ("GAME MANO ) WITH RESPECT TO CUSTOMER USE OF THE SERVICES. BY USING THE SERVICES, YOU AGREE TO BE BOUND BY EACH OF THE TERMS AND CONDITIONS SET OUT HERE IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS SET OUT IN THE, THEREFORE, YOU MAY NOT ACCESS, REGISTER, OR USE THE SERVICES GAME MANO  and the Customer may be referred to herein individually as a "Party" and collectively as "Parties.</p>
          <h3>DEFINITIONS</h3>
          <p>1.1. "Services" indicates the combination of software and support services as defined below.</p>
          <p>1.2. "Software" means GAME MANO  proprietary software in any form and related documentation provided or downloaded by Customer. The term "Software" also includes any updates, upgrades, or other new functionality, functionality, or enhancement of the software provided to the customer by GAME MANO  or its resellers or agents as part of a new purchase, through the assistance of the service or otherwise. All references to "buying" or "selling" the Software mean that you are granting a license to use that Software under the terms of this agreement.</p>
          <p>1.3. “Support Services” may include, depending on the Customer's purchasing choices, some or all of the following: product installation assistance and/or customary technical support and maintenance services.</p>

          <h3>GENERAL</h3>
          <p>2.1. Scope of the Agreement: This agreement applies to the purchase and use by the customer of all services of GAME MANO .</p>
          <p>2.2. Authority: Each party represents and warrants to the other party that: (a) its performance under this Agreement will not violate any applicable law; (b) is duly constituted, validly existing and in good standing under the laws of the jurisdiction of its formation; and (c) has the full right, power, and authority to enter into this Agreement and to perform its obligations hereunder.</p>
          
          <h3>LICENSE</h3>
          <p>Subject to the terms and conditions set forth in this Agreement, GAME MANO  grants Customer a non-exclusive, non-transferable, non-sublicensable, and revocable right and license to (a) install and use any version of the Software Object Code in accordance with the relevant documentation exclusively for its internal business operations; (b) reproduce and distribute internally a reasonable number of copies of the documentation relating to the Software provided by GAME MANO  (the "Documentation"), provided that the Customer includes on all copies of this Documentation all trademarks, names, logos, and notices therein as originally supplied by GAME MANO . GAME MANO  reserves all rights to the Software which are not expressly granted to Customer under this Agreement.</p>

          <h3>LIMITATIONS ON USE</h3>
          <p>4.1. No Right to Source Code: The customer acknowledges that the source code and the underlying structure and algorithms of the software are the property and exclusive trade secrets of GAME MANO . No license is granted to use the source code of the Software and such use is expressly prohibited, and the Customer undertakes not to: (i) modify, reverse engineer, decompile, translate or disassemble the Software, (ii) obtain or attempt to create, derive or obtain the source code of the Software, (iii) create a source code equivalent or derivative of the Software, or (iv) cause or permit any third party to perform any of the foregoing.</p>
          <p>4.2. No Right to Transfer, Resell or Provide Services: Customer is expressly prohibited from (a) assigning, distributing, licensing, sublicensing, selling, renting, leasing, granting security, or transferring any otherwise rights in or to the software; and (b) resell the Software, use the Software to provide any kind of paid or unpaid service to third parties (including, without limitation, incorporating any part of the Software into a product or service provided by Customer to third parties third party), or otherwise make the Software available to third parties, whether timeshare, service provider, hosting or otherwise.</p>
          <p>4.3. Use of Coded Instructions: Customer acknowledges and agrees that the Software may contain coded instructions which: (a) limit the number of users who may access the Software; (b) disable all or part of the functionality of the Software and any related electronic documentation upon the termination of the licenses granted hereunder and (c) contain other permissions or restrictions that may specifically be included in this Agreement. The Customer agrees not to interfere with any license key mechanism in the Software or to attempt to bypass, disable or interrupt any mechanism of the Software intended to control or limit the use of the Software.</p>
          <p>4.4. Other restrictions: Customer agrees not to: (a) remove, modify or cover any copyright notices, trademark notices or other proprietary rights notices placed or incorporated in or in the software or cause or to allow a third party to perform the foregoing; (b) use the licenses and rights granted under this Agreement to design, develop or distribute a commercial product or service that competes with the Software; (c) make available to third parties any analysis of the operating results of the Software, including the results of benchmarking, or otherwise disseminate information relating to the performance of the Software; or (d) use or distribute the Software in violation of any applicable law, regulation or export restriction.</p>

          <h3>PROPRIETARY RIGHTS</h3>
          <p>Between GAME MANO  and the Customer, GAME MANO  is and will remain the sole and exclusive owner of the Software and all intellectual property rights associated with it. The customer will not take any action incompatible with the ownership of GAME MANO  of each of the rights of GAME MANO  on the software and the associated intellectual property.</p>

          <h3>INDEMNIFICATION</h3>
          <p>6.1. GAME MANO  services are to be used for entertainment purposes only. The customer acknowledges that the Services may be subject to the laws and regulations of the United States, states, counties, and municipalities. Accordingly, the customer may only use the services in full compliance with all applicable laws and regulations. The customer is responsible for underestimating and using the Services in a way that does not violate federal, state, or local laws.</p>
          <p>6.2. Subject to the indemnification duties defined below, at its sole price and expense, Customer hereby is of the same opinion to shield and maintain innocent GAME MANO  and its associates and subsidiaries, and its and their officers, directors, stockholders, employees, consultants, representatives, agents, successors and assigns (the “Indemnitees”) in any 1/3 action or claim, and to indemnify GAME MANO  and its Indemnitees from and in opposition to any and all claims, losses, liabilities, sums of money, damages, expenses, and costs (including, however now no longer restrained to, affordable attorneys’ fees) (collectively, “Claims”) bobbing up from such motion or declare and associated to (a) Customer’s breach of any time period or condition, or of any of its representations or warranties, set forth on this Agreement; (b) Customer’s violation of relevant law; and/or (c) Customer’s gross negligence or willful misconduct.</p>
          </div>
        </div>
      </div>
    </>
    </>
  );

};



export default TermsComponent;
import React   from 'react';
// import './digital.scss';
import whiteLabel from "../../assets/white-label.png";
import b2c from "../../assets/b2c.png";
import operationSupport from "../../assets/operationSupport.png";
import riskManagement from "../../assets/risk-management.png";
import branding from "../../assets/branding.png";
import digitalMarketing from "../../assets/digital-marketing.png";
import Frame from "../../assets/Frame.png";
import graphicsDesign from "../../assets/graphics-design.png";
import b2b from "../../assets/operationmanager.json";
import {Link} from "react-router-dom";

import Lottie from "react-lottie-player";

import Services from "../services/services"
function OperationsSupportComponent(){
  
  return (
    <>
      <div className=" servicePages">
          <div className="container">
            <div className="row">
                <div className="col-lg-6 col-12 serviceImg">
                  <img src={operationSupport} className="d-block mx-auto" />
                </div>
                <div className="col-lg-6">
                    <h2>OPERATIONS SUPPORT</h2>
                    <p>With Game Mano’s iGaming operation support, you can stop having unsolvable problems haunting your business. We'll help you sort it out here and now so you can move on to better things. We know that running a successful iGaming online company is not as easy as it seems. There are loads of different industry practices to deal with; however, not everyone has the experience to solve the significant issues your company is facing. That's why Game Mano is here to help you. Our team of highly trained and experienced specialists are dedicated to your success. We'll work with you to create a personalized program that will give you the tools, knowledge, and confidence needed to succeed in the online gaming industry.</p>
                    <a href="contact"><button>Connect Now</button></a>

                </div>
                <div className="col-lg-6 col-12 serviceImgTwo">
                  <img src={operationSupport} className="d-block mx-auto" />
                </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-5 col-12">
                <Lottie
                  loop
                  animationData={b2b}
                  play
                  style={{
                    display: "block",
                    margin: "auto",
                  }}
                />
              </div>
              <div className="col-lg-7 col-12">
                  <h4 className='ServiceHeading'>Features</h4>

                  <ul className='ServiceList'>
                      <li>Effectively create strategies with regards to user-related aspects</li>
                      <li>Perform thorough risk analyses to assess possible threats and plan a strategy accordingly.</li>
                      <li>Maintain strong &amp; candid relationships with your customers by executing well thought out retention campaigns.</li>
                      <li>Beyond simply serving to acquire new users and retain old ones, turn user retention into a competitively-advantageous business model.</li>
                      <li>Online marketing methodologies, including website usability and conversion rate optimization</li>
                      <li>Determine potential risk levels using big data analytics.</li>

                  </ul>
              </div>
            </div>


            <div className="row mt-5">
              <div className="col-lg-12">
                  <h3 className='otherServices'>Our Other Services</h3>
                  
              </div>
              <div className="col-lg-4 col-md-4 col-12">
              <Link to="/btoc">
                  <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={b2c} />
                                    <h4>B2C</h4>

                                </div>
                                <div className="card-back">
                                    <h4>B2C</h4>
                                    <p>We bring market insights to your fingertips, presenting you with the right environment for success. Whether you're looking for a boost to your existing marketing effort or need the next 'Great Idea'</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                  </div>
                  </Link>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                    <Link to="/branding">
                      <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={branding} />
                                    <h4>Branding</h4>

                                </div>
                                <div className="card-back">
                                    <h4>Branding</h4>
                                    <p>We'll make sure your identity is unique and precise to you, that it meets all regulatory compliance standards and it's been built expertly on an all-inclusive brand package that's delivered instantaneously.</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                    </div>
                    </Link>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                 <Link to="/riskmanagement">
                  <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={riskManagement} />
                                    <h4>Risk Management</h4>

                                </div>
                                <div className="card-back">
                                    <h4>Risk Management</h4>
                                    <p>Risk is unavoidable in any business. As an owner, you need to take charge and make sure that your business is protected from all the risks that may come along.</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                  </div>
                  </Link>
              </div>
            </div>


          </div>
      </div>


      {/* <Services /> */}
      

    
        






    </>
  );

};



export default OperationsSupportComponent;
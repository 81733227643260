import React   from 'react';
import './teams.scss';



function Teams(){
  
  return (
    <>

     <div className="team-area section-padding">
  <div className="container">
      <div className="row">
          <div className="col-lg-12 col-12 mb-4">
              <h2 className='text-center'>Our Teams</h2>
          </div>
      </div>
    <div className="row">
      <div className="col-md-4">
        <div className="single-team">
          <div className="team-img">
            <img src="https://i.postimg.cc/T192DFG5/1.jpg" alt="" className="img-responsive" />
          </div>
          <div className="team-content">
            <div className="team-info">
              <h3> Claire Chedd</h3>
              <p> Designation</p>
            </div>
            <p className="team-text"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam quasi, vel.</p>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="single-team">
          <div className="team-img">
            <img src="https://i.postimg.cc/fR3ZzJ39/2.jpg" alt="" className="img-responsive" />
          </div>
          <div className="team-content">
            <div className="team-info">
              <h3> Laura Thorn</h3>
              <p> Designation</p>
            </div>
            <p className="team-text"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam quasi, vel.</p>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="single-team">
          <div className="team-img">
            <img src="https://i.postimg.cc/R0KBtyLC/3.jpg" alt="" className="img-responsive" />
          </div>
          <div className="team-content">
            <div className="team-info">
              <h3> Jane Doe</h3>
              <p> Designation</p>
            </div>
            <p className="team-text"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam quasi, vel.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    </>
  );

};



export default Teams;
import React   from 'react';
// import './digital.scss';
import whiteLabel from "../../assets/white-label.png";
import b2c from "../../assets/branding.png";
import operationSupport from "../../assets/operationSupport.png";
import riskManagement from "../../assets/risk-management.png";
import branding from "../../assets/branding.png";
import digitalMarketing from "../../assets/digital-marketing.png";
import Frame from "../../assets/Frame.png";
import graphicsDesign from "../../assets/graphics-design.png";

import Services from "../services/services";



import {Link} from "react-router-dom";
import Lottie from "react-lottie-player";
import b2b from "../../assets/branding.json";
function BrandingComponent(){
  
  return (
    <>
      <div className=" servicePages">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12 serviceImg">
                  <img src={b2c} className="d-block mx-auto" />
                </div>
                <div className="col-lg-6">
                    <h2>Branding</h2>
                    <p>We'll make sure your identity is unique and precise to you, that it meets all regulatory compliance standards and it's been built expertly on an all-inclusive brand package that's delivered instantaneously. Develop your branding process by looking at the different ways your business presents itself to customers. Identify what your business is about and make sure people know about it with our expert branding services. We offer a variety of branding strategies and customizable solutions that deliver accurate, engaging, and recognizable messages for your customers.</p>
                    <a href="contact"><button>Connect Now</button></a>

                </div>
                <div className="col-lg-6 col-12 serviceImgTwo">
                  <img src={b2c} className="d-block mx-auto" />
                </div>
            </div>


            <div className="row mt-3">
              <div className="col-lg-5 col-12">
                <Lottie
                  loop
                  animationData={b2b}
                  play
                  style={{
                    display: "block",
                    margin: "auto",
                  }}
                />
              </div>
              <div className="col-lg-7 col-12">
                  <h4 className='ServiceHeading'>Features</h4>

                  <ul className='ServiceList'>
                    <li>Build powerful brand identity that strengthens customer connection</li>
                    <li>Broaden and execute your brand's strategy</li>
                    <li>Create core values and elements that act as your physical identity</li>
                    <li>Analyze competition, curate appropriate messages, and bring alive the seamless brand identity with creative ad campaigns.</li>
                    <li>Unleash your business potential through carefully strategically planned branding work.</li>
                    <li>Strategy for a safe and thriving business for a long time</li>
                  </ul>
              </div>
            </div>


            <div className="row mt-5">
              <div className="col-lg-12">
                  <h3 className='otherServices'>Our Other Services</h3>
                  
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                <Link to="/btoc">
                  <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={b2c} />
                                    <h4>B2C</h4>

                                </div>
                                <div className="card-back">
                                    <h4>B2C</h4>
                                    <p>We bring market insights to your fingertips, presenting you with the right environment for success. Whether you're looking for a boost to your existing marketing effort or need the next 'Great Idea'</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                  <Link to="/operationssupport">
                  <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={operationSupport} />
                                    <h4>Operation Support </h4>

                                </div>
                                <div className="card-back">
                                    <h4>Operation Support </h4>
                                    <p>With Game Mano’s iGaming operation support, you can stop having unsolvable problems haunting your business. We'll help you sort it out here and now so you can move on to better things.</p>
                                    Read More

                                </div>
                            </div>
                  </div>
                  </Link>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                <Link to="/riskmanagement">
                  <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={riskManagement} />
                                    <h4>Risk Management</h4>

                                </div>
                                <div className="card-back">
                                    <h4>Risk Management</h4>
                                    <p>Risk is unavoidable in any business. As an owner, you need to take charge and make sure that your business is protected from all the risks that may come along.</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                  </div>
                  </Link>
              </div>
            </div>

          </div>
      </div>


      {/* <Services /> */}
      

    
        






    </>
  );
};



export default BrandingComponent;
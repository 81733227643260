import React , { useEffect , useState  }  from 'react';
import {Link} from "react-router-dom";
import highrollerFullLogo from '../../assets/PandaLogo.png';
import DataService from "../../data.service";
import facebook from '../../assets/facebook.png';
import instagram from '../../assets/instagram.png';
import logo from '../../assets/logo.png';

import './footer.css';


function FooterComponent(){
    const [settingData, setSettingData] = useState([]);

    useEffect(() => {
        DataService.getContent('website-settings')
        .then(response => {
            setSettingData(response.data);
        })
        .catch(e => {
            console.log(e);
        });
    }, []);
    
  return (
    <>  
        <div className="footer shadow-lg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-12">
                        <img alt="HighRoller_Logo" src={logo} className="footerLogo" />
                        {/* <p>{settingData.home_page_description}</p> */}
                        <p style={{  marginTop: "25px"}}><b>Get on our list.</b></p>
                        {/* <input type="text" className="subscribeInput" placeholder='Enter Your Email' /> */}
                        <p className='mt-4'><b>Email us at: </b><br></br><a className='mailtoLink' href="mailto:info@gamemano.com">info@gamemano.com</a></p>
                    </div>
                    <div className="col-lg-4 col-12">
                        <h3>Solutions</h3>
                        <ul className='footerList'>
                            <li><Link  to="/btob">B2B</Link></li>
                            <li><Link  to="/btoc">B2C</Link></li>
                            <li><Link  to="/digitalmarketing">Digital Marketing</Link></li>
                            <li><Link  to="/graphicdesign">Graphics Design</Link></li>
                            <li><Link  to="/branding">Branding</Link></li>
                            <li><Link  to="/riskmanagement">Risk Management</Link></li>
                            <li><Link  to="/operationssupport">Operations Support</Link></li>
                            <li><Link  to="/turnkey">Turn Keys</Link></li>

                        </ul>
                    </div>
                    <div className="col-lg-3 col-12">
                        <h3>Quick Links</h3>
                        <ul className='footerList'>
                            <li><Link  to="/about">About Us</Link></li>
                            <li><Link  to="/faqs">FAQ's</Link></li>
                            <li><Link  to="/terms">Terms and Conditions</Link></li>
                            <li><Link  to="/privacy">Privacy Policy</Link></li>
                            <li><Link  to="/process">Our Process</Link></li>
                            {/* <li><Link  to="/teams">Our Teams</Link></li> */}

                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
        {/* <div className="copyrightFooter">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <p className='text-center'>{settingData.copyright_text}</p>
                    </div>
                </div>
            </div>
        </div> */}
    </>
  );

};



export default FooterComponent;
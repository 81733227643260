import React , { useEffect , useState  }  from 'react';
import DataService from "../../data.service";
import FormComponent from '../homepage/form/forms';
import './contact.scss';
import fb from "../../assets/fb.png";
import insta from "../../assets/insta.png";
import youtube from "../../assets/youtube.png";
import b2b from "../../assets/contactus.json";
import Lottie from "react-lottie-player";
function ContactComponent(){
  
    
  return (
    <>
    <div className=" contactPage">
      <div className="container">
          <div className="row" style={{padding: "60px 0px",    alignItems: "center"}}>
            <div className="col-lg-6 col-md-6 col-12">
              <h2>Have Some Ideas?</h2>
              <p className='contactPageP'>Feel free to connect with us at: <br></br><a href="mailto:info@gamemano.com" target="_blank">info@gamemano.com</a></p>

              <p className='contactPageP'>Follow us on our social media pages:</p>
              <img src={fb}  style={{marginRight: "15px"}} />
              <img src={insta} style={{marginRight: "15px"}}  />
              <img src={youtube} style={{marginRight: "15px"}}  />

            </div>
            <div className="col-lg-6">
            {/* <FormComponent /> */}
            <Lottie
                  loop
                  animationData={b2b}
                  play
                  style={{
                    display: "block",
                    margin: "auto",
                  }}
                />
            </div>
          </div>
           
      </div>
    
    </div>
      
    </>
  );

};



export default ContactComponent;
import React   from 'react';
import './btoc.scss';
import whiteLabel from "../../assets/white-label.png";
import b2c from "../../assets/b2c.png";
import operationSupport from "../../assets/operationSupport.png";
import riskManagement from "../../assets/risk-management.png";
import branding from "../../assets/branding.png";
import digitalMarketing from "../../assets/digital-marketing.png";
import Frame from "../../assets/Frame.png";
import graphicsDesign from "../../assets/graphics-design.png";

import b2b from "../../assets/b2c.json";
import Lottie from "react-lottie-player";
import Services from "../services/services";
import {Link} from "react-router-dom";

function BtoCComponent(){
  
  return (
    <>
      <div className=" servicePages">
          <div className="container">
            <div className="row">
                <div className="col-lg-6 col-12 serviceImg">
                  <img src={b2c} className="d-block mx-auto" />
                </div>
                <div className="col-lg-6">
                    <h2>B2C</h2>
                    <p>We bring market insights to your fingertips, presenting you with the right environment for success. Whether you're looking for a boost to your existing marketing effort or need the next 'Great Idea' to gain traction and get ahead, have confidence our team has got your back!</p>
                    <a href="contact"><button>Connect Now</button></a>

                </div>
                <div className="col-lg-6 col-12 serviceImgTwo">
                  <img src={b2c} className="d-block mx-auto" />
                </div>
            </div>



            <div className="row mt-3">
              <div className="col-lg-5 col-12">
                <Lottie
                  loop
                  animationData={b2b}
                  play
                  style={{
                    display: "block",
                    margin: "auto",
                  }}
                />
              </div>
              <div className="col-lg-7 col-12">
                  <h4 className='ServiceHeading'>Features</h4>

                  <ul className='ServiceList'>
                      <li>Boost your impact with SEO and help rank higher in search engines.</li>
                      <li>Track your marketing campaigns with ease</li>
                      <li>Enjoy having a growing social following with all the tools you need</li>
                      <li>Professionally managed social media campaigns and advertisement generation.</li>
                      <li>Perform detailed market research to maintain a competitive edge.</li>
                      <li>Research what customers are talking about and how to get them take notice of you.</li>

                  </ul>
              </div>
            </div>



            <div className="row mt-5">
              <div className="col-lg-12">
                  <h3 className='otherServices'>Our Other Services</h3>
                  
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                    <Link to="/branding">
                    <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={branding} />
                                    <h4>Branding</h4>

                                </div>
                                <div className="card-back">
                                    <h4>Branding</h4>
                                    <p>We'll make sure your identity is unique and precise to you, that it meets all regulatory compliance standards and it's been built expertly on an all-inclusive brand package that's delivered instantaneously.</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                    </div>
                    </Link>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                  <Link to="/operationssupport">
                  <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={operationSupport} />
                                    <h4>Operation Support </h4>

                                </div>
                                <div className="card-back">
                                    <h4>Operation Support </h4>
                                    <p>With Game Mano’s iGaming operation support, you can stop having unsolvable problems haunting your business. We'll help you sort it out here and now so you can move on to better things.</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                  </div>
                  </Link>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                  <Link to="/riskmanagement">
                  <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={riskManagement} />
                                    <h4>Risk Management</h4>

                                </div>
                                <div className="card-back">
                                    <h4>Risk Management</h4>
                                    <p>Risk is unavoidable in any business. As an owner, you need to take charge and make sure that your business is protected from all the risks that may come along.</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                  </div>
                  </Link>
              </div>
            </div>

          </div>
      </div>


      {/* <Services /> */}
      

    
        






    </>
  );

};



export default BtoCComponent;
import React   from 'react';
import './process.scss';
import process from "../../assets/process.jpg";
import Services from "../services/services"
import Lottie from "react-lottie-player";

import processs from "../../assets/process.json";


 function Process(){
  
  return (
    <>
      <div className=" servicePages">
          <div className="container">
            <div className="row">
                <div className='col-lg-12'>
                <Lottie
                  loop
                  animationData={processs}
                  play
                  style={{
                    display: "block",
                    margin: "auto",
                    
                  }}
                />
                  {/* <img src={process} className="w-100" /> */}
                </div>

            </div>
            <div className="row mt-5" >
              <div className="col-lg-4 col-md-4 col-sm-4 col-12  ">
              <div className='processBox'>
                  <h5>PRE-PRODUCTION:</h5>
                  <p>PRE-PRODUCTION: Game Concept Analysis Game Design Document SRS Document Project-Plan with Milestone Art-Pipeline Set-up Technical Design Document Architecture – Design Test-Plan Finalising the right Art-style Flow & Wireframe Doc
                  </p>

                </div>


              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12  ">
              <div className='processBox'>
                  <h5>PRODUCTION:</h5>
                  <p>Start developing on the gaming modules Prioritise the most crucial parts and move it on to early sprints. Start Creating the Art-Assets Game Objects Background /Environments Animations Visual – effects Level-Design UI – Elements creations Game Balancing & Depth Audio Creation Review on each sprints & Work on the feedback . Optimization & Module Testing Integration of all the Modules.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12 ">
                <div className='processBox'>
                  <h5>POST-PRODUCTION:</h5>
                  <p>3rd Party SDK's Integrations QA &amp; Testing UAT Final Game Deliverables Monetisation Appstore Submissions Support &amp; Maintenance</p>
                </div>
             

              </div>
            </div>
          </div>
      </div>

    </>
  );

};



export default Process;
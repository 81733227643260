import './App.css';
import React , { useState  }  from 'react';
import HeaderComponent from './components/header/header';
import HeaderTwoComponent from './components/headertwo/headertwo';

import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomepageComponent from './components/homepage/homepage';
import GamePageComponent from './components/games/games';
import FooterComponent from './components/footer/footer';
import ContactComponent from './components/contact/contact';
import PrivacyComponent from './components/privacy/privacy';
import FaqsComponent from './components/faqs/faqs';
import TermsComponent from './components/terms/terms';
import StoreComponent from './components/store/store';
import GamedetailComponent from './components/gamedetail/gamedetail';
import NotFound from './components/notfound/notfound';
import SearchComponent from './components/search/search';
import ScrollToTop from './scrolltotop';

import AboutComponent from './components/about/about';

import SolutionsComponent from './components/solutions/solutions';
import BtoBComponent from './components/btob/btob';
import BtoCComponent from './components/btoc/btoc';
import DigitalMarketingComponent from './components/digitalmarketing/digitalmarketing';
import OperationsSupportComponent from  './components/operationssupport/oprationssupport';
import RiskManagementComponent from './components/riskmanagement/riskmanagement';
import GraphicDesignComponent from './components/graphicdesign/graphicdesign';
import WhiteLabelComponent from './components/whitelabel/whitelabel';
import BrandingComponent from './components/branding/branding';
import TurnKeyComponent from './components/turnkey/turnkey';

import Process from './components/process/process';
import Teams from './components/teams/teams';

function App() {
 

  return (
    <>
    <Router >
        <ScrollToTop />
        {window.location.pathname !== "/" ? <HeaderTwoComponent />  :  <HeaderComponent />}

        <Routes>
              <Route path="/" element={<HomepageComponent />} />
              <Route path="/about" element={<AboutComponent />} />
              <Route path="/btob" element={<BtoBComponent key={window.location.pathname} />} />
              <Route path="/btoc" element={<BtoCComponent />} />
              <Route path="/turnkey" element={<TurnKeyComponent />} />

              <Route path="/digitalmarketing" element={<DigitalMarketingComponent />} />
              <Route path="/operationssupport" element={<OperationsSupportComponent />} />
              <Route path="/riskmanagement" element={<RiskManagementComponent />} />
              <Route path="/graphicdesign" element={<GraphicDesignComponent />} />
              <Route path="/whitelabel" element={<WhiteLabelComponent />} />
              <Route path="/branding" element={<BrandingComponent />} />
              <Route path="/teams" element={<Teams />} />
              <Route path="/process" element={<Process />} />
              <Route path="/solutions" element={<SolutionsComponent />} />
              <Route path="/contact" element={<ContactComponent />} />
              <Route path="/faqs" element={<FaqsComponent />} />
              <Route path="/terms" element={<TermsComponent />} />
              <Route path="/privacy" element={<PrivacyComponent />} />
              <Route path="*"  element={<NotFound />} />
              {/* <Route path="/games" element={<GamePageComponent />} />
              <Route path="/game/:gametype" element={<GamePageComponent />} /> */}
             
              {/* <Route path="/store" element={<StoreComponent />} />
              <Route path="/gamedetail/:id" element={<GamedetailComponent />} />
              <Route path="/search/" element={<SearchComponent />} />
              <Route path="/search/:string" element={<SearchComponent />} /> */}
              
        </Routes>
        <FooterComponent />  
    </Router>

    </>

  );
}

export default App;

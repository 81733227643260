import React , { useEffect , useState  }  from 'react';
import DataService from "../../data.service";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './faqs.css';
import { Accordion,  AccordionItem,  AccordionItemHeading,  AccordionItemButton,  AccordionItemPanel,} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

function FaqsComponent(){
    const [gameData, setGameData] = useState([]);
    const [gameDatatwo, setGameDataTwo] = useState([]);
    const [gameDatathree, setGameDataThree] = useState([]);

    useEffect(() => {
        DataService.getData('faq',100)
        .then(response => {
            setGameData(response.data.results);
            setGameDataTwo(response.data.results);
            setGameDataThree(response.data.results);
        })
        .catch(e => {
            console.log(e);
        });
    }, []);


  return (
    <>
        <div className="faqpage">
            <div className="container">
                <div className="row">
                    <div className="col-12 faqTitle">
                        <h4 style={{color: "#00C1DB", fontSize: "16px"}} className="text-center">The FAQ's</h4>
                        <h2 className='text-center'>Help Center</h2>
                        <p className='text-center'>Need Answers?Find them here...</p>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-6">
                        <p className='couldnotfind'>Couldn't find what you are looking for? <br></br>Write us at: <a href="#" className='link'>info@gamemano.com</a></p>
                    </div>
                    <div className="col-lg-6">
                   
                            <Accordion  allowMultipleExpanded={true} allowZeroExpanded={true} >
                                         <AccordionItem   >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                Which are the gaming platforms available? 
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>Different smart gadgets are running on different operating systems or platforms and the popular ones are Smartphone: Apple iOS, Android, WindowsDesktop: PC (Windows), Mac &amp; LinuxOnline: Browser and Facebook games.</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem   >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                How important are games?                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>The popularity of smart gadgets like smartphones, tablets etc has opened up a new avenue of entertainment in the software industry and that is the domain of Mobile gaming sector. And now games market is a big partof mobile ecosystem and is estimated to reach $14.4 billion by 2017.</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>


                                        <AccordionItem   >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                How i will get revenue from games?                                           </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>As soon as your game is live, you will start earning your revenue and profits through selling your game (paid games) and in-app purchase. If the gameswere free to play then monetization would be through in-app purchase, in-game advertisements, etc.</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem   >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>Does gamification make games or other apps more addictive?</AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>The simplistic answer is that if gamification is done well, it indeed should make something more“addictive”. Good gamification will make you want to continue, for the thrill and for the satisfaction, sense of mastery and sense of completion.</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem   >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>Will my website design be search engine friendly?</AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>All our websites are created with search engines in mind and the website design is compliant with search engine guidelines. It is a good idea for your website to be re-evaluated in time as over a period of time the search engines do alter their rules and algorithms and you will want to be certain that your website is kept up to date and complies with new rules.</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem   >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>Do you develop mobile app for all platforms?</AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>Yes, we build mobile applications for all major mobile development platforms. You can hire our skilled developers for developing app for iOS (iPhone and iPad),Android, and Windows Mobile. Whether you want to develop apps for just one or two platforms, or want to develop an app that works on all the mobile platforms, we are at your service.</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem   >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>What's the difference between a native and a hybrid application?</AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>A native app is written specifically for one operating system, like Android or iOS. A hybrid app has some native code but also relies on programming languages that all operating systems can understand.Juego Studio offers both hybrid and native app development services because both approaches have their advantages. Hybrid apps can sometimes be faster and more cost effective to deploy to multiple mobile operating systems, but native apps typically perform more smoothly and quickly.</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem   >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>What is your project development methodology?</AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                
                                                <p>Generally we follow these steps:</p>
                                                <ul>
                                                    <li>Communicate and understand your requirements</li>
                                                    <li>Research and Analysis</li>
                                                    <li>Data gathering and Consulting</li>
                                                    <li>Framing and Designing</li>
                                                    <li>Development</li>
                                                    <li>Testing &amp;  QA</li>
                                                    <li>Deployment•Support</li>
                                                </ul>
                                                <p>We are highly flexible, and are open to discuss methods you are comfortable with.</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem   >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>How is Quality ensured?</AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                
                                                <p>We follow strict standards to ensure the security and quality of your project. We have specialized people for QA and QC that after every project being processed here. Rigorous testing at all phases is done and we do not release a project to our client until we are satisfied with it.</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem   >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>Do you provide websites which are compatible for mobile devices?</AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                
                                                <p>Yes, we do make websites which are compatible for smartphones and tablets by using HTML5 and CSS. This kind of design termed as Responsive Web Design and this is our specialty.</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem   >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>Which types of UI or graphics alternatives are provided by you?</AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                
                                                <p>We do provide a lot of options when it comes to design. But if you give us proper instructions about your needs, we will definitely make some good designs as per your needs.</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem   >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>Can you use my own graphics and UI?</AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                
                                                <p>Yes we can use your graphics and UI if you want, or we can design ourselves as per your requirement.</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem   >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>How can I test the app at the time of its development ?</AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                
                                                <p>We always make sure that you must get regular updates in every stage at the time of app development. Once the app is all set for testing purpose, we will ask you for the information regarding your device. After that we will send you the app, so that youcan test the app on your device constantly before its release.</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        
                                        
                                                                    </Accordion>
                       
                    </div>
                </div>
            </div>
        </div>
    </>
  );

};



export default FaqsComponent;
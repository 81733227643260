import React , { useEffect , useState  }  from 'react';
import './solutions.scss';
import DataService from "../../data.service";
import Services from "../services/services"

function SolutionsComponent(){
  const [gameData, setGameData] = useState([]);

  useEffect(() => {
    DataService.getContent('about-us')
    .then(response => {
        setGameData(response.data.results);
    })
    .catch(e => {
        console.log(e);
    });
  }, []);
  return (
    <>
      <div className=" solutionsPage">
          <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <h2>SOLUTIONS</h2>
                    <p>Give your iGaming business the boost it needs by utilizing Game Mano’s professional services! From White Label and Turnkey solutions, to B2B Support, Digital Marketing, and everything in between, we've got you covered!</p>
                    <button>Connect Now</button>

                </div>
            </div>


          </div>
      </div>
      <Services />
    </>
  );

};



export default SolutionsComponent;
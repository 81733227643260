

import React , { useEffect , useState  }  from 'react';
import { Link } from 'react-router-dom';
// import GameGridCard from './GameGridCard/GameGridCard';
 // import GameListCard from './GameListCard/GameListCard';
import GameMenu from './Menu/GameMenu';
import './games.css';
import './GameListCard.css';
import './GameGridCard.css';
import DataService from "../../data.service";
import { useParams } from "react-router-dom";
import fallbackone from '../../assets/fallbackone.jpg';
import magnify from  '../../assets/magnifyingGlass.svg';



 
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation"
import SwiperCore, { Autoplay,Navigation} from 'swiper';
SwiperCore.use([Autoplay,Navigation]);



function GamePageComponent() {
  const [gameData, setGameData] = useState([]);
  const [gameType, setGameType] = useState(null);
  const [newGameType, setNewGameType] = useState(null);

  const [gameTypee, setGameTypee] = useState(null);
  const [zeroResult, setZeroResult] = useState(true);

  const [newgameData, setNewGameData] = useState([]);


  const [gameCategory, setGameCategory] = useState(0);

  const [listView, setlistView] = useState(false);
  const [gridView, setgridView] = useState(true);

  const listviewfunctions = () => {
    setlistView(true);
    setgridView(false);
  };
  const gridviewfunctions = () => {
    setgridView(true);
    setlistView(false);

  };

  const { gametype } = useParams();

  useEffect(() => {
    if(gametype){
      DataService.getAll(`${gametype}`)
      .then(response => {
          setGameData(response.data.results);
          console.log(response.data.results)
      })
      .catch(e => {
          console.log(e);
      });
    }else{
      DataService.getAll()
    .then(response => {
        setGameData(response.data.results);
    
    })
    .catch(e => {
        console.log(e);
    });
    }
    
}, [gametype]);



useEffect(() => {
  DataService.gameType(gameTypee, gameCategory)
  .then(response => {
      setGameData(response.data.results);
      
      if(response.data.results.type === '1'){
        setGameType("Fish")
      }else if(response.data.results.type === 2){
        setGameType("Slot")
      }
      else if(response.data.results.type === 3){
        setGameType("Keno")
      }else if(response.data.results.type === 4){
        setGameType("Other")
      }

      if(response.data.count === 0){
        setZeroResult(true)
      }else{
        setZeroResult(false)
      }

  })
  .catch(e => {
      console.log(e);
  });
}, [gameTypee, gameCategory]);
  

useEffect(() => {
  DataService.getGameType( )
  .then(response => {
    setNewGameType(response.data.results);
   
  })
  .catch(e => {
      console.log(e);
  });


  DataService.getAll()
  .then(response => {
      setNewGameData(response.data.results);
  })
  .catch(e => {
      console.log(e);
  });


}, []);
  

  const changeDropdown = (event) =>{
  
    setGameCategory(event.target.value)

  }

  const changeDropdownGameTwo = (event) =>{
    setGameTypee(event.target.value)
   
  }

  // <div className={`ribbon ribbon-top-right  ${item.new === true ? 'show' :  'hide'}` }><span>NEW</span></div>

  return (
  
    <>    
    <div className="recommendedSection">
      <div className="container">
        <div className="row m-0">
            <h3 className='recommendedSectionTitle' >Recommended Games</h3>
            <Swiper autoplay={{"delay": 2500,"disableOnInteraction": false }}  loop={true} navigation={true}  slidesPerView={1} spaceBetween={10}  breakpoints={{
              "640": { "slidesPerView": 2, "spaceBetween": 20 },
              "768": { "slidesPerView": 3, "spaceBetween": 20 },
              "1024": { "slidesPerView": 5, "spaceBetween": 20 }  }} className="recommendedSlider">
                {newgameData.map((item, i) => (
                    <SwiperSlide key={i}  >
                        <Link to={`/gamedetail/${item.id}`}>
                                <div className="sliderBox">
                                    <img src={item.image} className='w-100 imagePopup'  alt='SliderImage' />
                                    <h3>{item.name}</h3>
                                </div>
                        </Link>
                              
                  </SwiperSlide>
                ))}
            </Swiper>
        </div>
      </div>
    </div>
      <div className='page gameBox gameBoxImg'>
        <div className="container">
           <div className="mb-5 d-block">
        <label className='labelName'>Sort By</label>
                  
                  <select className='dropdownFilter' onChange={changeDropdown}>
                    <option vvalue="">All Games</option>
                    <option value="popular" selected={gametype === 'popular'}>Popular Game</option>
                    <option value="recommended"  selected={gametype === 'recommended'}>Recommended Game</option>
                    <option value="latest"  selected={gametype === 'latest'}>Latest Game</option>
                    <option value="trending" selected={gametype === 'trending'}>Trending Game</option>
                    <option value="highly-rated" selected={gametype === 'highly-rated'}>Highly Rated Game</option>
                  </select>

           </div>
        <div className="gameMenu">
            <div className='gameMenuGrid' >

                <div >
                  <label className='labelName'>Game Category</label>
                  <select className='dropdownFilter'  onChange={changeDropdownGameTwo}>
                  {/* {newGameType.map((item, i) => (
                    <option value={item.id}>{item.name} </option>
                  ))} */}
                    <option value="" >All</option>
                    <option value="1">Fish </option>
                    <option value="2">Slot</option>
                    <option value="3">Keno</option>
                    {/* <option value="4">Other</option> */}
                  </select>
                </div>
                <div>
                  <label className='labelName'>Game Type</label>
                  
                  <select className='dropdownFilter' onChange={changeDropdown}>
                    <option vvalue="">All Games</option>
                    <option value="popular" selected={gametype === 'popular'}>Popular Game</option>
                    <option value="recommended"  selected={gametype === 'recommended'}>Recommended Game</option>
                    <option value="latest"  selected={gametype === 'latest'}>Latest Game</option>
                    <option value="trending" selected={gametype === 'trending'}>Trending Game</option>
                    <option value="highly-rated" selected={gametype === 'highly-rated'}>Highly Rated Game</option>
                  </select>
                </div>



                
            </div>
            <div>
            <GameMenu gridviewfunction={gridviewfunctions}  listviewfunction={listviewfunctions} />

            </div>
          </div>  
          {/*<div className="noresultBox">*/}
            <h2 className={`zeroResult ${zeroResult === true ? "hideZero" : ''}`}><img className='magnifyGlass' src={magnify}></img>Nothing Found</h2>

          {/*</div>*/}

          <div className={`${gridView === true ? 'd-block' : 'd-none'}`}>
            <div className=''>
                <div className="row">
                {gameData.map((item, i) => (
                   <Link className="col-lg-3 col-sm-6 col-12" key={i}  to={`/gamedetail/${item.id}`}>

                    <div className="homepageLatestCard">

                    {item.image ? (<img src={item.image} alt="Game_Image" className='w-100'  /> ) : (<img src={fallbackone} alt="Lorem" className='w-100' />)}                      

                  
                    <div className='homepageLatestCardContent'>
                      <div>
                        <h4 className='title'>{item.name}</h4>
                        {/* <p className='cardDescription'>
                          
                        {item.game_type === 1 ? ('Fish' ) : ('')}
                        {item.game_type === 2 ? ('Slot' ) : ('')}
                        {item.game_type === 3 ? ('Keno' ) : ('')}
                        {item.game_type === 4 ? ('Other' ) : ('')}
                        </p> */}
                      </div>
                      <div>
                        <button className='joinnowBtn'>Join Now</button>
                      </div>
                    </div>
                    </div>
                  </Link>
                ))}
                </div>
            </div>
          </div>
          <div className={`${listView === true ? 'd-block' : 'd-none'}`}>
            {/* <GameListCard dataParentToChild = {gameData}/> */}

            <div id='game_list_card'>
              {gameData.map((item, i) => (
                <Link key={i} className='ListCard'  to={`/gamedetail/${item.id}`}>
                  <div className={`ribbon ribbon-top-right  ${item.new === true ? 'show' :  'hide'}` }><span>NEW</span></div>

                  <div className='ListGrid'>
                    <div>
                      {item.image ? (<img src={item.image} alt="Game_Image" className='listImage w-100'  /> ) : (<img src={fallbackone} alt="Lorem" className=' listImage w-100' />)}                      
                    </div>
                    <div></div>
                    <div className='GameSection'>
                      <h3 className='ListText'>{item.name}</h3>
                      {/* <p className='ListCategory'> {item.game_type === 1 ? ('Fish' ) : ('')}
                      {item.game_type === 2 ? ('Slot' ) : ('')}
                      {item.game_type === 3 ? ('Keno' ) : ('')}
                      {item.game_type === 4 ? ('Other' ) : ('')}</p> */}
                      <div className='GameDescriptionBoxTablet'>{item.description}</div>
                    </div>
                    <div className='GameDescriptionBox'>{item.description}</div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
         
      </div>
    </>

  );
}

export default GamePageComponent;

import React   from 'react';
import './services.scss';
import whiteLabel from "../../assets/white-label.png";
import b2c from "../../assets/b2c.png";
import operationSupport from "../../assets/operationSupport.png";
import riskManagement from "../../assets/risk-management.png";
import branding from "../../assets/branding.png";
import digitalMarketing from "../../assets/digital-marketing.png";
import Frame from "../../assets/Frame.png";
import graphicsDesign from "../../assets/graphics-design.png";
import {Link} from "react-router-dom";


function Services(){
  
  return (
    <>
  

      <div className="sectionOne">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <h2>A Complete Solution to <br></br>Your Online Game Development</h2>
                    </div>
                   
                </div>
                <div className="row " >
                    <div className="col-lg-4 col-md-4 col-12">                     
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                        <Link to="/turnkey">
                        <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={whiteLabel} />
                                    <h4>Turn Key</h4>

                                </div>
                                <div className="card-back">
                                    <h4>Turn Key</h4>
                                    <p>We are a turn-key solution providing all the consultation, technical and service support needed to set up an iGaming business!</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                    </div>
                </div>
                <div className="row cardRow" >
                    <div className="col-lg-4 col-md-4 col-12 cardRowLeft"  >
                        <Link to="/btob">
                        <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={Frame} />
                                    <h4>B2B</h4>

                                </div>
                                <div className="card-back">
                                    <h4>B2B</h4>
                                    <p>As a B2B provider for iGaming Business, our expertise in customer service will be a great value for your company. We provide you with the means to keep your customers satisfied and loyal.</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                        </div>
                        </Link>
                        <Link to="/graphicdesign">
                        <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={graphicsDesign} />
                                    <h4>Graphic Design</h4>

                                </div>
                                <div className="card-back">
                                    <h4>Graphic Design</h4>
                                    <p>Game Mano is dedicated to creating your brand's visual identity. Our team of exemplary designers has the expertise, technical skills, and creativity to create eye-catching graphics, compelling designs, and perfect layouts.</p>
                                    <b>Read More</b>


                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                        <Link to="/btoc">
                        <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={b2c} />
                                    <h4>B2C</h4>

                                </div>
                                <div className="card-back">
                                    <h4>B2C</h4>
                                    <p>We bring market insights to your fingertips, presenting you with the right environment for success. Whether you're looking for a boost to your existing marketing effort or need the next 'Great Idea'</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                        </div>
                        </Link>
                        <Link to="/operationssupport">
                        <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={operationSupport} />
                                    <h4>Operation Support </h4>

                                </div>
                                <div className="card-back">
                                    <h4>Operation Support </h4>
                                    <p>With Game Mano’s iGaming operation support, you can stop having unsolvable problems haunting your business. We'll help you sort it out here and now so you can move on to better things.</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                        </div>
                        </Link>
                        <Link to="/riskmanagement">
                        <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={riskManagement} />
                                    <h4>Risk Management</h4>

                                </div>
                                <div className="card-back">
                                    <h4>Risk Management</h4>
                                    <p>Risk is unavoidable in any business. As an owner, you need to take charge and make sure that your business is protected from all the risks that may come along.</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12 cardRowRight">
                        <Link to="/branding">
                        <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={branding} />
                                    <h4>Branding</h4>

                                </div>
                                <div className="card-back">
                                    <h4>Branding</h4>
                                    <p>We'll make sure your identity is unique and precise to you, that it meets all regulatory compliance standards and it's been built expertly on an all-inclusive brand package that's delivered instantaneously.</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                        </div>
                        </Link>
                        <Link to="/digitalmarketing">
                        <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={digitalMarketing} />
                                    <h4>Digital Marketing</h4>

                                </div>
                                <div className="card-back">
                                    <h4>Digital Marketing</h4>
                                    <p>Gain visibility where your customers go and tap into the digital marketing space today. Traditional marketing is so last century: today’s marketing is filled with automation, affiliate campaigns, and much more.</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>
                
            </div>
        </div>
        






    </>
  );

};



export default Services;
import React , { useEffect , useState  }  from 'react';
import './header.css';
import './header.scss';

import {Link} from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import magnifyingGlass from '../../assets/search.svg';
import MenuIcon from '../../assets/menu.svg';
import DataService from "../../data.service";
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import logo from '../../assets/logo.png';


function HeaderComponent(props){
  const [showResults, setShowResults] = React.useState(false)
  const [searchText, setSearchText] = React.useState(0)
  const [hideSearchBtn, setHideSearchBtn] = React.useState(false)
  const [lolBg, setlolBg] = useState(0);

    const [menuOpenState, setmenuOpenState] = React.useState(false)

    const menuOpen = () => {  setmenuOpenState(!menuOpenState) }
  const onClick = () => setShowResults(true)

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const showDropdown = (e)=>{
      setShow(!show);
  }
  const hideDropdown = e => {
      setShow(false);
  }
  const handleInputChange = event => {
    setSearchText(event.target.value);
   
  };

  const handleKeyDown = (e) => {
    if(e.target.value.length < 3){
      setHideSearchBtn(true)
    }else{
      setHideSearchBtn(false)
    }
    if (e.key === 'Enter') {
      navigate(`/search/${e.target.value}`, { replace: true })
      setSearchText(e.target.value);
    }
  }

  

  useEffect(() => {
    DataService.getHeaderBg().then(response => {
      setlolBg(response.data.logo); 
    }).catch(e => {
      console.log(e);
    });
  }, []);


  return (
    <>
        
        
        <div className="header headerOne" id={window.location.pathname !== "/" ? 'headerTwo'  :  "headerOne" } > 
          <div className="container">
            <div className="row">
              <div className="col-12">
              <Navbar expand="lg">
                  
                  <Navbar.Brand href="/"> <img alt="High_Roller_logo" className="desktopLogo"  src={logo}/></Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse className='justify-content-end' id="basic-navbar-nav">
                    <Nav className="me-auto">
                      <Nav.Link href="./">Home</Nav.Link>
                      <Nav.Link href="./about">About</Nav.Link>
                      {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                      </NavDropdown> */}
                        <NavDropdown     show={show}  onMouseEnter={showDropdown}  onMouseLeave={hideDropdown} title="Solutions" id="basic-nav-dropdown">
                          <NavDropdown.Item href="/btob">B2B</NavDropdown.Item>
                          <NavDropdown.Item href="/btoc">B2C</NavDropdown.Item>
                          <NavDropdown.Item href="/digitalmarketing">Digital Marketing</NavDropdown.Item>
                          <NavDropdown.Item href="/branding">Branding</NavDropdown.Item>
                          <NavDropdown.Item href="/riskmanagement">Risk Management</NavDropdown.Item>
                          <NavDropdown.Item href="/graphicdesign">Graphic Design</NavDropdown.Item>
                          <NavDropdown.Item href="/operationssupport">Operation Support</NavDropdown.Item>
                          <NavDropdown.Item href="/turnkey">Turn Keys</NavDropdown.Item>

                        </NavDropdown>                      
                        <Nav.Link href="./process">Process</Nav.Link>
                      <Nav.Link href="./contact" className='blueBtn'>Contact</Nav.Link>

                    </Nav>
                  </Navbar.Collapse>
              </Navbar>
              </div>
            
            </div>
            {/* <div className="row" style={{    alignItems: "center"}}>
                <div className="col-lg-1 col-3">
                    <Link to="/"><img alt="High_Roller_logo" className="desktopLogo"  src={`https://admin.pandamastersweeps.com/media/${lolBg}`}/></Link>
                </div>
                <div className="col-lg-11">
                  <div className="menuGrid">
                      <div><Link to="./">Home</Link></div>
                      <div><Link to="./about">About</Link></div>
                      <div><Link to="./games">Games</Link></div>
                      
                      <div><Link to="./contact">Contact</Link></div>
                      <div><a target="_blank" href="https://pandamaster.vip:8888/index.html"><button className='downloadBtn'>Download</button></a></div>
                      <div>
                        <div className='sarchInputWarpper'> 
                          <input type="text" className='searchInput'  onKeyDown={handleKeyDown}  onChange={handleInputChange}  placeholder="Search Here" />
                          <button><img className='magnifyGlass' src={magnifyingGlass}/></button>  
                        </div>
                      </div>
                  </div>
                </div>
                
            </div>*/}
          </div> 
         
          
        </div>

{/* 
        <div className="header d-block d-sm-block d-lg-none">
          <div className="container">
            <div className="row" style={{    alignItems: "center"}}>
                <div className="col-lg-3 col-9">
                    <Link to="/" className="mb-0"><img alt="High_Roller_logo" className="mobileLogo"  src={`https://admin.pandamastersweeps.com/media/${lolBg}`}/><span className="mobileLogo"></span></Link>
                </div>

               <div className="col-lg-3 col-3">
                   <img className="float-right" onClick={menuOpen} src={MenuIcon} />

               </div>
            </div>
          </div>
        </div>
        <div id="MenuBox" className={`${menuOpenState === true ? 'd-block' : 'd-none'}`}>
          <ul className="mobileList">
            <li><Link to="./">Home</Link></li>
            <li><Link to="./">About</Link></li>
            <li><Link to="./">Games</Link></li>
            <li><Link to="./">Contact</Link></li>
            <li><Link to="./" className="downloadBtn">Download</Link></li>
            
          </ul>
            <div className='sarchInputWarpper'> 
                          <input type="text" className='searchInput'  onKeyDown={handleKeyDown}  onChange={handleInputChange}  placeholder="Search Here" />
                          <button><img className='magnifyGlass' src={magnifyingGlass}/></button>  
                        </div>
        </div>
       */}
    </>
  );

};



export default HeaderComponent;
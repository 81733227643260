import React   from 'react';
import './turnkey.scss';
import whiteLabel from "../../assets/white-label.png";
import b2c from "../../assets/b2c.png";
import operationSupport from "../../assets/operationSupport.png";
import riskManagement from "../../assets/risk-management.png";
import branding from "../../assets/branding.png";
import digitalMarketing from "../../assets/digital-marketing.png";
import Frame from "../../assets/Frame.png";
import graphicsDesign from "../../assets/graphics-design.png";
import {Link} from "react-router-dom";

import Services from "../services/services"
import Lottie from "react-lottie-player";
import b2b from "../../assets/whitelabel.json";
function TurnKeyComponent(){
  
  return (
    <>
      <div className=" servicePages">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12 serviceImg">
                  <img src={whiteLabel} className="d-block mx-auto" />
                </div>
                <div className="col-lg-6">
                    <h2>Turn Keys </h2>
                    <p>We are a turn-key solution providing all the consultation, technical and service support needed to set up an iGaming business! We provide you with everything necessary, including licensing, location selection, terminology consulting & translation as well as service guidance and maintenance. Leave the heavy lifting to us.</p>
                    <a href="contact"><button>Connect Now</button></a>

                </div>
                <div className="col-lg-6 col-12 serviceImgTwo">
                  <img src={whiteLabel} className="d-block mx-auto" />
                </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-5 col-12">
                <Lottie
                  loop
                  animationData={b2b}
                  play
                  style={{
                    display: "block",
                    margin: "auto",
                  }}
                />
              </div>
              <div className="col-lg-7 col-12">
                  <h4 className='ServiceHeading'>Features</h4>

                  <ul className='ServiceList'>
                      <li>Complete turn-key solutions so you can focus more on your business</li>
                      <li>Stick to your formula and turn that clutter into momentum.</li>
                      <li>Custom-built solution to smooth out the process.</li>
                      <li>Crypto payment options for a more secure and anonymous way of paying.</li>
                      <li>one-time custom developments as well as integral solutions.</li>
                      <li>integration with your existing systems</li>
                      <li>iGaming solution that is customized to your needs.</li>
                      <li>Built in Risk Management for Top Safety and Protection.</li>

                  </ul>
              </div>
            </div>
                  

            <div className="row mt-5">
              <div className="col-lg-12">
                  <h3 className='otherServices'>Our Other Services</h3>
                  
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                  <Link to="/btoc">
                  <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={b2c} />
                                    <h4>B2C</h4>

                                </div>
                                <div className="card-back">
                                    <h4>B2C</h4>
                                    <p>We bring market insights to your fingertips, presenting you with the right environment for success. Whether you're looking for a boost to your existing marketing effort or need the next 'Great Idea'</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                  </div>
                  </Link>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                  <Link to="/operationssupport">
                  <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={operationSupport} />
                                    <h4>Operation Support </h4>

                                </div>
                                <div className="card-back">
                                    <h4>Operation Support </h4>
                                    <p>With Game Mano’s iGaming operation support, you can stop having unsolvable problems haunting your business. We'll help you sort it out here and now so you can move on to better things.</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                  </div>
                  </Link>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                  <Link to="/riskmanagement">
                  <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={riskManagement} />
                                    <h4>Risk Management</h4>

                                </div>
                                <div className="card-back">
                                    <h4>Risk Management</h4>
                                    <p>Risk is unavoidable in any business. As an owner, you need to take charge and make sure that your business is protected from all the risks that may come along.</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                  </div>
                  </Link>
              </div>
            </div>

          </div>
      </div>


      {/* <Services /> */}
      

    
        






    </>
  );

};



export default TurnKeyComponent;
import React , { useEffect , useState  }  from 'react';
import './btob.scss';
import whiteLabel from "../../assets/white-label.png";
import b2c from "../../assets/Frame.png";
import operationSupport from "../../assets/operationSupport.png";
import riskManagement from "../../assets/risk-management.png";
import branding from "../../assets/branding.png";
import digitalMarketing from "../../assets/digital-marketing.png";
import Frame from "../../assets/Frame.png";
import graphicsDesign from "../../assets/graphics-design.png";
import {Link} from "react-router-dom";

import Lottie from "react-lottie-player";
import b2b from "../../assets/b2b.json";



import Services from "../services/services"
function BtoBComponent(){
  useEffect(() => { }, []);
  return (
    <>
      <div className=" servicePages">
          <div className="container">
            <div className="row">
                <div className="col-lg-6 col-12 serviceImg">
                  <img src={b2c} className="d-block mx-auto" />
                </div>
                <div className="col-lg-6">
                    <h2>B2B</h2>
                    <p>As a B2B provider for iGaming Business, our expertise in customer service will be a great value for your company. We provide you with the means to keep your customers satisfied and loyal. We can offer everything from customer support and live chat to technical support. With over 12 years of experience in the industry, we also have a network of professionals that can provide further assistance, no matter what you need!</p>
                    <a href="contact"><button>Connect Now</button></a>

                </div>
                <div className="col-lg-6 col-12 serviceImgTwo">
                  <img src={b2c} className="d-block mx-auto" />
                </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-5 col-12">
                <Lottie
                  loop
                  animationData={b2b}
                  play
                  style={{
                    display: "block",
                    margin: "auto",
                  }}
                />
              </div>
              <div className="col-lg-7 col-12">
                  <h4 className='ServiceHeading'>Features</h4>

                  <ul className='ServiceList'>
                    <li>End-to-end set of solutions for you business</li>
                    <li>All the features you need to enjoy seamless integration and ease of use</li>
                    <li>Combining superior performance with multiple integrated solutions</li>
                    <li>Simpler setup and faster configuration with the most intuitive interface</li>
                    <li>Streamlined configuration process of your Game Provider Platform</li>
                    <li>Stay on top of your Risk Management to avoid surprises that could hurt your performance</li>
                    <li>Get all the integrations you need to maximize the efficiency of your business</li>
                    <li> Understand how everything is performing with a detailed breakdown</li>
                    <li>Features CRM and integrations with major third party providers.</li>
                    <li>Makes full use of the latest performance analytics tools to deliver optimal results.</li>

                  </ul>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-12">
                  <h3 className='otherServices'>Our Other Services</h3>
                  
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                <Link to="/btoc">
                    <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={b2c} />
                                    <h4>B2C</h4>

                                </div>
                                <div className="card-back">
                                    <h4>B2C</h4>
                                    <p>We bring market insights to your fingertips, presenting you with the right environment for success. Whether you're looking for a boost to your existing marketing effort or need the next 'Great Idea'</p>
                                    Read More
                                    <b>Read More</b>
                                </div>
                            </div>
                  </div>
                  </Link>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                  <Link to="/operationssupport">
                  <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={operationSupport} />
                                    <h4>Operation Support </h4>

                                </div>
                                <div className="card-back">
                                    <h4>Operation Support </h4>
                                    <p>With Game Mano’s iGaming operation support, you can stop having unsolvable problems haunting your business. We'll help you sort it out here and now so you can move on to better things.</p>
                                    Read More

                                </div>
                            </div>
                  </div>
                  </Link>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                  <Link to="/riskmanagement">
                  <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={riskManagement} />
                                    <h4>Risk Management</h4>

                                </div>
                                <div className="card-back">
                                    <h4>Risk Management</h4>
                                    <p>Risk is unavoidable in any business. As an owner, you need to take charge and make sure that your business is protected from all the risks that may come along.</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                  </div>
                  </Link>
              </div>
            </div>
          </div>
      </div>


      {/* <Services /> */}
      

    
        






    </>
  );

};



export default BtoBComponent;
import React   from 'react';

import whiteLabel from "../../assets/white-label.png";
import b2c from "../../assets/b2c.png";
import operationSupport from "../../assets/operationSupport.png";
import riskManagement from "../../assets/risk-management.png";
import branding from "../../assets/branding.png";
import digitalMarketing from "../../assets/digital-marketing.png";
import Frame from "../../assets/Frame.png";
import graphicsDesign from "../../assets/graphics-design.png";
import Services from "../services/services"

import {Link} from "react-router-dom";

import Lottie from "react-lottie-player";
import b2b from "../../assets/graphicdesign.json";
function GraphicDesignComponent(){
  
  return (
    <>
      <div className=" servicePages">
          <div className="container">
            <div className="row">
                <div className="col-lg-6 col-12 serviceImg">
                  <img src={graphicsDesign} className="d-block mx-auto" />
                </div>
                <div className="col-lg-6">
                    <h2>GRAPHICS DESIGN</h2>
                    <p>Game Mano is dedicated to creating your brand's visual identity. Our team of exemplary designers has the expertise, technical skills, and creativity to create eye-catching graphics, compelling designs, and perfect layouts. Whatever your business needs—web design, graphic design, banners, posters, and character designs— turn to our creative designers for a stellar solution.</p>
                    <a href="contact"><button>Connect Now</button></a>

                </div>
                <div className="col-lg-6 col-12 serviceImgTwo">
                  <img src={graphicsDesign} className="d-block mx-auto" />
                </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-5 col-12">
                <Lottie
                  loop
                  animationData={b2b}
                  play
                  style={{
                    display: "block",
                    margin: "auto",
                  }}
                />
              </div>
              <div className="col-lg-7 col-12">
                  <h4 className='ServiceHeading'>Features</h4>

                  <ul className='ServiceList'>
                      <li>Engage with more deep character designs through our premade Stickers and Frames.</li>
                      <li>Use one-of-a-kind mockups to make your designs stand out!</li>
                      <li>Fully rounded solution for designing and modelling products</li>
                      <li>Quality you can be proud of. Forget using bad quality stock images, start making your feed magical again.</li>
                      <li>Design a product and show it the way it deserves, without any hassle</li>
                  </ul>
              </div>
            </div>


            <div className="row mt-5">
              <div className="col-lg-12">
                  <h3 className='otherServices'>Our Other Services</h3>
                  
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                 <Link to="/btoc">
                  <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={b2c} />
                                    <h4>B2C</h4>

                                </div>
                                <div className="card-back">
                                    <h4>B2C</h4>
                                    <p>We bring market insights to your fingertips, presenting you with the right environment for success. Whether you're looking for a boost to your existing marketing effort or need the next 'Great Idea'</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                      
                  </div>
                  </Link>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                   <Link to="/operationssupport">
                  <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={operationSupport} />
                                    <h4>Operation Support </h4>

                                </div>
                                <div className="card-back">
                                    <h4>Operation Support </h4>
                                    <p>With Game Mano’s iGaming operation support, you can stop having unsolvable problems haunting your business. We'll help you sort it out here and now so you can move on to better things.</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                  </div>
                  </Link>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
              <Link to="/riskmanagement">
                  <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={riskManagement} />
                                    <h4>Risk Management</h4>

                                </div>
                                <div className="card-back">
                                    <h4>Risk Management</h4>
                                    <p>Risk is unavoidable in any business. As an owner, you need to take charge and make sure that your business is protected from all the risks that may come along.</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                  </div>
                  </Link>
              </div>
            </div>


            
          </div>
      </div>


    
      

    
        






    </>
  );
};



export default GraphicDesignComponent;
import React , { useEffect , useState  }  from 'react';
import './about.scss';
import DataService from "../../data.service";
import aboutImg from "../../assets/aboutImg.jpg";
import b2b from "../../assets/about.json";
import Lottie from "react-lottie-player";


import devraj from "../../assets/devraj-upreti.jpg";
import george from "../../assets/george-uperti.jpg";
import subarna from "../../assets/subarna-upreti.jpg";
import vu from "../../assets/vu-nuygen.jpg";
import john from "../../assets/john-nyugen.jpg";
import bobby from "../../assets/bobby-upreti.jpg";


function AboutComponent(){
  const [gameData, setGameData] = useState([]);

  useEffect(() => {
    DataService.getContent('about-us')
    .then(response => {
        setGameData(response.data.results);
    })
    .catch(e => {
        console.log(e);
    });
  }, []);
  return (
    <>
      <div className="aboutPage">
          <div className="container">
            <div className="row">
              <div className="aboutBox">
                <div className="row" style={{    alignItems: "center"}}>
                  <div className="col-lg-6">
                      <h2 className='mb-3'>About Us</h2>
                      <p>GameMano is a Game Developmental Solutions providing company.</p>  
                      <p>Give your iGaming business the boost it needs by utilizing GameMano's professional services! From Graphic Design, Turnkey solutions to B2B Support, Digital Marketing, and everything in between, we've got you covered!</p>

                  </div>
                  <div className='col-lg-6 col-12'>
                      {/* <img src={aboutImg} className="w-100 d-block mx-auto" /> */}

                      <Lottie
                    loop
                    animationData={b2b}
                    play
                    style={{
                      display: "block",
                      margin: "auto",
                    }}
                  />
                  </div>
                </div>
{/* 
                <div className="row">
                  <div className="col-lg-12">
                    <h3 className='mb-4'>Meet Our Team</h3>

                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-sm-4 col-6 teambox">
                    <img src={bobby}  className="w-100" />
                    <h3 className='teamname'>Bobby Upreti</h3>
                    <h4 className='teampost'>CEO</h4>
                  </div>

                  <div className="col-lg-4 col-sm-4 col-6 teambox">
                    <img src={devraj} className="w-100" />
                    <h3 className='teamname'>Devraj Upreti</h3>
                    <h4 className='teampost'>CFO</h4>
                  </div>

                  <div className="col-lg-4 col-sm-4 col-6 teambox">
                    <img src={subarna}  className="w-100" />
                    <h3 className='teamname'>Subarna Upreti </h3>
                    <h4 className='teampost'>Marketing Director</h4>
                  </div>


                  <div className="col-lg-4 col-sm-4 col-6 teambox">
                    <img src={george}  className="w-100" />
                    <h3  className='teamname'>George Upreti</h3>
                    <h4 className='teampost'>Sales Director</h4>
                  </div>


                  <div className="col-lg-4 col-sm-4 col-6 teambox">
                    <img src={vu} className="w-100" />
                    <h3  className='teamname'>Vu Nuygen</h3>
                    <h4 className='teampost'>Studio Manager</h4>
                  </div>

              
                  <div className="col-lg-4 col-sm-4 col-6 teambox">
                    <img src={john}  className="w-100"/>
                    <h3  className='teamname'>Jhon Nuygen</h3>
                    <h4 className='teampost'>Lead QA</h4>
                  </div>

             
                  

                 
                 
                </div>*/}
              </div> 


            </div>
          </div>
      </div>
    </>
  );

};



export default AboutComponent;
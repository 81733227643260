import React   from 'react';
// import './digital.scss';
import whiteLabel from "../../assets/white-label.png";
import b2c from "../../assets/b2c.png";
import operationSupport from "../../assets/operationSupport.png";
import riskManagement from "../../assets/risk-management.png";
import branding from "../../assets/branding.png";
import digitalMarketing from "../../assets/digital-marketing.png";
import Frame from "../../assets/Frame.png";
import graphicsDesign from "../../assets/graphics-design.png";

import Services from "../services/services";

import Lottie from "react-lottie-player";
import b2b from "../../assets/riskmanagement.json";
import {Link} from "react-router-dom";


function RiskManagementComponent(){
  
  return (
    <>
      <div className=" servicePages">
          <div className="container">
            <div className="row">
                <div className="col-lg-6 col-12 serviceImg">
                  <img src={riskManagement} className="d-block mx-auto" />
                </div>
                <div className="col-lg-6">
                    <h2>Risk Management</h2>
                    <p>Risk is unavoidable in any business. As an owner, you need to take charge and make sure that your business is protected from all the risks that may come along. We offer comprehensive risk management services to safeguard you and your business from all possible challenges. We'll work with you to develop a risk management strategy that best suits business needs and provides the peace of mind that you deserve. Just get in touch with us today and we will be there for you when needed!</p>
                    <a href="contact"><button>Connect Now</button></a>

                </div>
                <div className="col-lg-6 col-12 serviceImgTwo">
                  <img src={riskManagement} className="d-block mx-auto" />
                </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-5 col-12">
                <Lottie
                  loop
                  animationData={b2b}
                  play
                  style={{
                    display: "block",
                    margin: "auto",
                  }}
                />
              </div>
              <div className="col-lg-7 col-12">
                  <h4 className='ServiceHeading'>Features</h4>

                  <ul className='ServiceList'>
                      <li>Understand how gross margins are trending</li>
                      <li>24/7 Monitoring without disruption, so you can focus on more progressive strategies.</li>
                      <li>Set up alerts and make outbound calls to stop decreased margins mid-sale and systematically make sales by catching problems within the process.</li>
                      <li>Analyze your product's data trends and competitive landscape, ensuring the necessary actions are taken</li>
                      <li>Keep an eye on any variable cost that is increasing unexpectedly and reduce it</li>
                      <li>Stay informed with global market developments and extraction of data.</li>
                      <li>Gain insights into other things as they happen.</li>

                  </ul>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-12">
                  <h3 className='otherServices'>Our Other Services</h3>
                  
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                 <Link to="/btoc">
                  <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={b2c} />
                                    <h4>B2C</h4>

                                </div>
                                <div className="card-back">
                                    <h4>B2C</h4>
                                    <p>We bring market insights to your fingertips, presenting you with the right environment for success. Whether you're looking for a boost to your existing marketing effort or need the next 'Great Idea'</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                  </div>
                  </Link>
              </div>
            
              <div className="col-lg-4 col-md-4 col-12">
                  <Link to="/operationssupport">
                  <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={operationSupport} />
                                    <h4>Operation Support </h4>

                                </div>
                                <div className="card-back">
                                    <h4>Operation Support </h4>
                                    <p>With Game Mano’s iGaming operation support, you can stop having unsolvable problems haunting your business. We'll help you sort it out here and now so you can move on to better things.</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                  </div>
                  </Link>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                        <Link to="/graphicdesign">
                        <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={graphicsDesign} />
                                    <h4>Graphic Design</h4>

                                </div>
                                <div className="card-back">
                                    <h4>Graphic Design</h4>
                                    <p>Game Mano is dedicated to creating your brand's visual identity. Our team of exemplary designers has the expertise, technical skills, and creativity to create eye-catching graphics, compelling designs, and perfect layouts.</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                        </div>
                        </Link>
              </div>
            </div>


          </div>
      </div>


      {/* <Services /> */}
      

    
        






    </>
  );

};



export default RiskManagementComponent;
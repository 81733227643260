import React , {  useState  }  from 'react';
import './forms.css';
import ReactModal from 'react-modal';
import DataService from "../../../data.service";
import {Link} from "react-router-dom";
import messageSent from '../../../assets/sent.png';


function FormComponent(){
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      };
    const [modalIsOpen, setIsOpen] = React.useState(false);
    
    const [validationAlphabet, setValidationAlphabet] = React.useState(false)
    const [validationEmail, setValidationEmail] = React.useState(false);
    const [fullname, setFullname] = React.useState(false);
    const [radioBtn, setRadioBtn] = React.useState('0');

    const [disabledForm, setDisabledForm] = React.useState(false);

    const [requestType, setRequestType] = React.useState('0')
    const validateEmail = (email) =>  {
      const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
      const result = pattern.test(email);
      
      if(result===true){
        setValidationEmail(true);
        setDisabledForm(false)

      } else{
        console.log(false)
        setValidationEmail(false);
        setDisabledForm(true)
      }
    }

    const alphabetValidation = (name) => {
      var reg_name_lastname = /^[a-zA-Z\s]*$/;
      const result = reg_name_lastname.test(name);
      if(result===true){
        setValidationAlphabet(true);
        setDisabledForm(false)
      }else{
        setValidationAlphabet(false);
        setDisabledForm(true)
      }
    }

    function openModal() {
      setIsOpen(true);
    }
    function closeModal() {
      setIsOpen(false);
    }
    

    const initialTutorialState = {  
        name: "",
        city: "",
        phone: "",
        email: "",
        interest: "",
        message: "",
        request_type: ""
    };
    const [tutorial, setTutorial] = useState(initialTutorialState);
    const handleInputChange = event => {

        const { name, value } = event.target;
        setTutorial({ ...tutorial, [name]: value });
    };
    const handleEmailChange = event => {
        validateEmail(event.target.value)
        setTutorial({ ...tutorial, 'email':  event.target.value });
    }

    const handleNameChange = event =>{
      alphabetValidation(event.target.value)
      setTutorial({ ...tutorial, 'name':  event.target.value });

    }
    const personalBtn = () => {
      setRadioBtn('personal')
      
    }
    const BusinessBtn = () => {
      setRadioBtn('business')
    

    }
    const sendForm = () => {
        alphabetValidation(tutorial.name)

        var data = {
          name: tutorial.name,
          // city: tutorial.city,
          city: 'null',
          email: tutorial.email,
          phone: tutorial.phone,
          interest: tutorial.interest,
          message: tutorial.message,
          request_type:  'null'
        };

        if(tutorial.request_type  === 'Personal'){
          setRadioBtn('personal')
        }else if(tutorial.request_type  === 'Business'){
          setRadioBtn('business')
        }
        setRequestType(tutorial.request_type )
        if(validationEmail){
          console.log("Email Validate")
        }
        
        DataService.create(data).then(response => {   
          setIsOpen(true)
          console.log(response.status)
        }).catch(e => {
          console.log(e);
        });
    };
    
  return (
    <>
{/* https://t.me/highrollersweepsdistributor */}
        {/* <button onClick={openModal}>Open Modal</button> */}
        {/* <div onClick={closeModal}  className={`modaloverlay ${modalIsOpen === true ? "" : "hideModal"} `}></div> */}
        <ReactModal  isOpen={modalIsOpen} style={customStyles} contentLabel="Example Modal" >
            <img src={messageSent} className='messageSent'/>
            <h3>Your message has been send successful</h3>
            <a  target="_blank" href="https://t.me/Highrollerofficial" className='outlinebtn'>Visit our Telegram</a>
            <button onClick={closeModal} className='closeBtn'>close</button>
        </ReactModal>
        <div className="formBoxx">
            {/* <h1 className="formHeading">Get in Touch</h1> */}
             <form onSubmit={e => e.preventDefault()}>
            <div className="FormLayout">
              <div className='row'>
                <div className='col-lg-6 col-6'>
                  <label htmlFor="">Name</label>
                  <input className="InputOne"    required={true}  name="name"  onChange={handleInputChange} type="text" placeholder="Enter your full Name" />
                </div>
                <div  className='col-lg-6 col-6'>
                  <label htmlFor="">Phone</label>
                  <input className="InputOne" required={true} name="email"  onChange={handleInputChange} type="email" placeholder="Enter your Email" />
                </div>
              </div>
              <div className='row'>
                <div  className='col-lg-12 col-12'>
                    <label htmlFor="">Phone</label>
                    <input  type="tel" className="InputOne"   required={true}  onChange={handleInputChange} name='phone' placeholder="Enter Phone" />
                </div>  
              </div>
              <div className='row'>
                <div className='col-lg-12 col-12'>
                  <label htmlFor="">Intrest</label>
                  <input className="InputOne"    required={true}  name="interest"  onChange={handleInputChange} type="text" placeholder="Interest " />
                </div>
              </div>
                      {/*<select name='interest' required={true} className="InputOne" onChange={handleInputChange}>
                          <option value="Player">Player</option>
                          <option value="Business Distributor">Business Distributor</option>
                          <option value="New Location">New Location</option>
                          <option value="Existing Location">Exisiting Location</option>
                      </select>*/}
               
                <div className='row'>
                  <div className='col-lg-12 col-12'>
                      <label>Message</label>
                      <textarea className="InputOne"   name='message' required={true} onChange={handleInputChange} placeholder="Type your message"></textarea>
                  </div>
                </div>
                {/* <div className="InputOne" >
                    <label htmlFor="" >Request Type</label>
                </div> 
                <div className="row">
                  <div className="col-lg-6 col-6">
                      <div className="RadioBtn" ><input  required={true}  name="request_type" value="Personal" onChange={handleInputChange} type="radio"></input><span>Personal</span></div>
                  </div>
                  <div className="col-lg-6 col-6">
                    <div className="RadioBtn"><input   required={true}  name="request_type" value="Business"  onChange={handleInputChange} type="radio"></input><span>Business</span></div>

                  </div>
                </div> */}
                <div className="row">
                  <div className="col-lg-6 col-6">
                    {/* <input type="reset" className="ResetBtn" value="Reset"></input> */}
                  </div>
                <div className="col-lg-6 col-6">
                    <button  className={`SendBtn ${disabledForm === true ? "d-none" : "d-block"}`} onClick={sendForm}>Send</button>
                  </div>
                  
                  
                </div> 
                <div className={`messageBox ${modalIsOpen === true  ? 'd-block' : 'd-none'}`} > 
              
                  
                  <h3>Your message has been sent successful</h3>
                  <a  target="_blank" href="https://t.me/Highrollerofficial" className={`outlinebtn ${radioBtn === 'personal' ? 'showimp' : 'hideimp'}`}>Visit our Telegram</a>
                  <a target="_blank" href="https://t.me/highrollersweepsdistributor" className={`outlinebtn ${radioBtn === 'business' ? 'showimp' : 'hideimp'}`}>Visit our Telegram</a>
                </div> 
            </div>
            </form>
            
        </div>
      
    </>
  );

};



export default FormComponent;
import React   from 'react';
import digitalMarketing from "../../assets/digital-marketing.png";
 
import whiteLabel from "../../assets/white-label.png";
import b2c from "../../assets/b2c.png";
import operationSupport from "../../assets/operationSupport.png";
import riskManagement from "../../assets/risk-management.png";
import branding from "../../assets/branding.png";
import Frame from "../../assets/Frame.png";
import graphicsDesign from "../../assets/graphics-design.png";
import b2b from "../../assets/digitalmarketing.json";
import {Link} from "react-router-dom"; 

import Lottie from "react-lottie-player";

function DigitalMarketingComponent(){
  
  return (
    <>
      <div className=" servicePages">
          <div className="container">
            <div className="row">
                <div className="col-lg-6 col-12 serviceImg">
                  <img src={digitalMarketing} className="d-block mx-auto" />
                </div>
                <div className="col-lg-6">
                    <h2>Digital Marketing</h2>
                    <p>Gain visibility where your customers go and tap into the digital marketing space today. Traditional marketing is so last century: today’s marketing is filled with automation, affiliate campaigns, and much more. Digital marketing has the benefit of being able to target specific demographics as well as your business has access to a very wide audience, with no geographic boundaries. Take your products to interested people instead of just anyone. Target and intuitively advertise, right online, to people who are interested in your business.</p>
                    <a href="contact"><button>Connect Now</button></a>

                </div>
                <div className="col-lg-6 col-12 serviceImgTwo">
                  <img src={digitalMarketing} className="d-block mx-auto" />
                </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-5 col-12">
                <Lottie
                  loop
                  animationData={b2b}
                  play
                  style={{
                    display: "block",
                    margin: "auto",
                  }}
                />
              </div>
              <div className="col-lg-7 col-12">
                  <h4 className='ServiceHeading'>Features</h4>

                  <ul className='ServiceList'>
                      <li>Best of every marketing strategy under one roof.</li>
                      <li>Give your business a reach it deserves with personalized email marketing that converts.</li>
                      <li>Optimize the visibility of your website in search browsers by way of SEO and other services.</li>
                      <li>Start earning steady and timely revenue by making use of various social media methods to drive in leads and conversions.</li>
                      <li>Instantly create quotes, interact with customers, generate reports or build targeted ads in seconds.</li>
                      <li>Set up any kind of campaign you want across every sector you want.</li>

                  </ul>
              </div>
            </div>


            <div className="row mt-5">
              <div className="col-lg-12">
                  <h3 className='otherServices'>Our Other Services</h3>
                  
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                  <Link to="/btoc">
                  <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={b2c} />
                                    <h4>B2C</h4>

                                </div>
                                <div className="card-back">
                                    <h4>B2C</h4>
                                    <p>We bring market insights to your fingertips, presenting you with the right environment for success. Whether you're looking for a boost to your existing marketing effort or need the next 'Great Idea'</p>
                                    <b to="/btoc">Read More</b>

                                </div>
                            </div>
                  </div>
                  </Link>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                <Link to="/operationssupport">
                  <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={operationSupport} />
                                    <h4>Operation Support </h4>

                                </div>
                                <div className="card-back">
                                    <h4>Operation Support </h4>
                                    <p>With Game Mano’s iGaming operation support, you can stop having unsolvable problems haunting your business. We'll help you sort it out here and now so you can move on to better things.</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                  </div>
                  </Link>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
              <Link to="/riskmanagement">
                  <div className="card-wrap">
                            <div className="card">
                                <div className="card-front">
                                    <img src={riskManagement} />
                                    <h4>Risk Management</h4>

                                </div>
                                <div className="card-back">
                                    <h4>Risk Management</h4>
                                    <p>Risk is unavoidable in any business. As an owner, you need to take charge and make sure that your business is protected from all the risks that may come along.</p>
                                    <b>Read More</b>

                                </div>
                            </div>
                  </div>
                  </Link>
              </div>
            </div>
          </div>
      </div>


      {/* <Services /> */}
      

    
        






    </>
  );

};



export default DigitalMarketingComponent;